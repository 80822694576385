import { useMemo } from "react"
import { useLocation } from "@remix-run/react"

import type { PageId } from "@/config/pages"
import { useDetailsPages } from "@/components/shared/details/use-details-state"

export const useNavItemState = (path: string, id: PageId) => {
	const pagesState = useDetailsPages()
	const location = useLocation()
	const isActive = location.pathname === path

	const pathWithState = useMemo(() => {
		const pageState = pagesState[id]?.singleState
		if (!pageState) return path

		const params = new URLSearchParams()
		Object.entries(pageState).forEach(([key, value]) => {
			params.append(key, value)
		})

		return `${path}?${params.toString()}`
	}, [path, pagesState, id])

	return { isActive, pathWithState }
}
