import { useThemeSwitch } from "@/routes/utils+/theme-switch"
import { useClerk } from "@clerk/remix"
import { LogOutIcon } from "lucide-react"

import {
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
} from "@/components/ui/dropdown-menu"

export const UserDropdownContent = () => {
	const [theme, setTheme] = useThemeSwitch()
	const { signOut } = useClerk()

	return (
		<>
			<DropdownMenuGroup>
				<DropdownMenuLabel>Theme</DropdownMenuLabel>
				<DropdownMenuRadioGroup
					value={theme}
					onValueChange={(value) =>
						setTheme(value as "light" | "dark" | "system")
					}
				>
					<DropdownMenuRadioItem value="light">
						Light
					</DropdownMenuRadioItem>
					<DropdownMenuRadioItem value="dark">
						Dark
					</DropdownMenuRadioItem>
					<DropdownMenuRadioItem value="system">
						System
					</DropdownMenuRadioItem>
				</DropdownMenuRadioGroup>
			</DropdownMenuGroup>

			<DropdownMenuSeparator />

			<DropdownMenuGroup>
				<DropdownMenuLabel>Account</DropdownMenuLabel>
				<DropdownMenuItem onClick={() => signOut()}>
					<LogOutIcon />
					<span>Log out</span>
					<DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
				</DropdownMenuItem>
			</DropdownMenuGroup>
		</>
	)
}
