import { motion } from "framer-motion"

import { cn } from "@/lib/utils/classnames"
import { Logo as SVGLogo } from "@/components/shared/logo"

import { useNavbar } from "../../context"

export const ExpandedLogo = ({ className }: { className?: string }) => {
	const { animations } = useNavbar()
	return (
		<motion.div
			{...animations.expandedLogo}
			className={cn("mx-auto shrink-0", className)}
		>
			<SVGLogo className={cn("w-full max-w-[200px] dark:hidden")} />
			<SVGLogo
				color="white"
				className={cn("hidden w-full max-w-[200px] dark:block")}
			/>
		</motion.div>
	)
}
