import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useReducedMotion } from "framer-motion"

import { useIsMobile } from "@/lib/hooks/use-is-mobile"
import { TooltipProvider } from "@/components/ui/tooltip"

import { getAnimations } from "./animations"
import { NAVBAR_KEYBOARD_SHORTCUT } from "./constants"

interface NavbarContext {
	state: "expanded" | "collapsed"
	isExpanded: boolean
	setIsExpanded: (open: boolean) => void
	openMobile: boolean
	setOpenMobile: (open: boolean) => void
	isMobile: boolean
	toggleNavbar: () => void
	animations: ReturnType<typeof getAnimations>
}

// Context & Hook
const NavbarContext = createContext<NavbarContext | null>(null)

export const useNavbar = () => {
	const context = useContext(NavbarContext)
	if (!context) {
		throw new Error("useNavbar must be used within a NavbarProvider")
	}
	return context
}

// Provider Component
export const NavbarProvider = ({ children }: { children: React.ReactNode }) => {
	const isMobile = useIsMobile()
	const [isExpanded, setIsExpanded] = useState(true)
	const [openMobile, setOpenMobile] = useState(false)
	const prefersReducedMotion = useReducedMotion()

	const toggleNavbar = useCallback(() => {
		return isMobile ?
				setOpenMobile((open) => !open)
			:	setIsExpanded((open) => !open)
	}, [isMobile])

	// Reset mobile navigation state on desktop
	useEffect(() => {
		if (isMobile) return
		setOpenMobile(false)
	}, [isMobile])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (
				event.key === NAVBAR_KEYBOARD_SHORTCUT &&
				(event.metaKey || event.ctrlKey)
			) {
				event.preventDefault()
				toggleNavbar()
			}
		}

		window.addEventListener("keydown", handleKeyDown)
		return () => window.removeEventListener("keydown", handleKeyDown)
	}, [toggleNavbar])

	const contextValue: NavbarContext = useMemo(
		() => ({
			state: isExpanded ? "expanded" : "collapsed",
			isExpanded,
			setIsExpanded,
			isMobile,
			openMobile,
			setOpenMobile,
			toggleNavbar,
			animations: getAnimations(!!prefersReducedMotion),
		}),
		[isExpanded, isMobile, openMobile, toggleNavbar, prefersReducedMotion],
	)

	return (
		<NavbarContext.Provider value={contextValue}>
			<TooltipProvider delayDuration={0}>{children}</TooltipProvider>
		</NavbarContext.Provider>
	)
}
