import { useMemo } from "react"
import { useLocation } from "@remix-run/react"
import { motion } from "framer-motion"
import { ChevronRightIcon } from "lucide-react"

import type { PageGroup } from "@/config/pages"
import { cn } from "@/lib/utils/classnames"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible"
import { typographyVariants } from "@/components/ui/typography"

import { useNavbar } from "../../context"
import { NavbarExpandedItem } from "./item"

export const NavbarExpandedGroup = ({ group }: { group: PageGroup }) => {
	const location = useLocation()
	const isActive = useMemo(
		() => group.pages.some((item) => item.path === location.pathname),
		[group.pages, location.pathname],
	)
	const { animations } = useNavbar()

	return (
		<motion.div {...animations.expandedGroup}>
			<Collapsible defaultOpen={isActive}>
				<CollapsibleTrigger
					className={cn(
						typographyVariants({ variant: "overline" }),
						"group flex w-full items-center space-x-2 rounded p-2 transition-colors hover:bg-hover",
					)}
				>
					<ChevronRightIcon className="size-4 shrink-0 -translate-y-px transition-transform group-data-[state=open]:-translate-y-0 group-data-[state=open]:rotate-90" />
					<span className="font-medium">{group.label}</span>
				</CollapsibleTrigger>
				<CollapsibleContent className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
					<div className="space-y-1 py-1">
						{group.pages.map((item) => (
							<NavbarExpandedItem key={item.id} page={item} />
						))}
					</div>
				</CollapsibleContent>
			</Collapsible>
		</motion.div>
	)
}
