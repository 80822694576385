import { motion } from "framer-motion"

import type { PageGroup } from "@/config/pages"

import { useNavbar } from "../../context"
import { NavbarCollapsedItem } from "./item"

export const NavbarCollapsedGroup = ({
	group,
	hasSeparator,
}: {
	group: PageGroup
	hasSeparator: boolean
}) => {
	const { state, animations } = useNavbar()

	return (
		<>
			{hasSeparator && (
				<motion.div
					{...animations.collapsedSeparator}
					animate={state === "collapsed" ? "visible" : "hidden"}
					className="h-px w-full bg-foreground/[15%]"
				/>
			)}
			{group.pages.map((item) => (
				<motion.div key={item.id} {...animations.collapsedItem}>
					<NavbarCollapsedItem page={item} />
				</motion.div>
			))}
		</>
	)
}
